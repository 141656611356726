import { capitalize, ref } from 'vue'
import { defineStore } from 'pinia'
import { isAxiosError } from 'axios'

export type ToastType = 'notice' | 'info' | 'warning' | 'error'

export interface Toast {
  id: number
  message: string
  type?: ToastType
  duration?: number
  timeout?: ReturnType<typeof setTimeout>
}

export type ToastInput = Omit<Toast, 'id'>

const defaultToast = (message: string, type: ToastType): ToastInput => ({
  message,
  type,
  duration: 5000
})

export const useToastStore = defineStore('toast', () => {
  const messages = ref<Toast[]>([])

  const alert = (value: string | ToastInput, toastType: ToastType = 'notice') => {
    const toastInput = typeof value === 'string' ? defaultToast(value, toastType) : value
    const toast: Toast = {
      ...toastInput,
      id: new Date().getTime()
    }
    messages.value.push(toast)
    if (typeof toast.duration === 'number') {
      toast.timeout = setTimeout(() => {
        const index = messages.value.findIndex(i => i.id === toast.id)
        messages.value.splice(index, 1)
      }, toast.duration)
    }
  }

  const handleError = (error: Error | unknown) => {
    if (isRechargeRequestError(error)) {
      const errorMessage = Object.entries(JSON.parse(error.message))
        .map(([ key, value ]) => `${capitalize(key)}: ${value}`)
        .join('\n')
      alert(errorMessage, 'error')
    } else if (isAxiosError(error) && error?.response?.data?.error) {
      alert(error?.response?.data?.error, 'error')
    } else if (isErrorWithMessage(error)) {
      alert(error.message, 'error')
    } else {
      console.error(error)
      sendToSentry(error)
    }
  }

  const reset = () => {
    messages.value.forEach(i => i.timeout && clearTimeout(i.timeout))
    messages.value = []
  }

  return {
    messages,
    handleError,
    alert,
    reset
  }
})
