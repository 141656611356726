import { default as editMIsenHFALUMeta } from "/vercel/path0/src/pages/account/edit.vue?macro=true";
import { default as indexy2dPqYVViOMeta } from "/vercel/path0/src/pages/account/index.vue?macro=true";
import { default as edit3mo2V1ncLwMeta } from "/vercel/path0/src/pages/account/payment-methods/[id]/edit.vue?macro=true";
import { default as newA9BVtI91vYMeta } from "/vercel/path0/src/pages/account/payment-methods/new.vue?macro=true";
import { default as activate5ELYE4hzfuMeta } from "/vercel/path0/src/pages/activate.vue?macro=true";
import { default as customer0TLxWbjsj1Meta } from "/vercel/path0/src/pages/admin/customer.vue?macro=true";
import { default as index1kcBTKlDMxMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as login8p7DdjFDWsMeta } from "/vercel/path0/src/pages/admin/login.vue?macro=true";
import { default as set_45passwordkrGVglQHZRMeta } from "/vercel/path0/src/pages/admin/set-password.vue?macro=true";
import { default as favoritesshdhZYL6TnMeta } from "/vercel/path0/src/pages/favorites.vue?macro=true";
import { default as giftingtf45OMbNscMeta } from "/vercel/path0/src/pages/gifting.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as loginLL9eJtzKSFMeta } from "/vercel/path0/src/pages/login.vue?macro=true";
import { default as logoutzKCEFgv0brMeta } from "/vercel/path0/src/pages/logout.vue?macro=true";
import { default as editPp7z1tdd4qMeta } from "/vercel/path0/src/pages/onetimes/[id]/edit.vue?macro=true";
import { default as indexnTX5TxR4WVMeta } from "/vercel/path0/src/pages/onetimes/[id]/index.vue?macro=true";
import { default as _91key_936CsS4xh9x8Meta } from "/vercel/path0/src/pages/orders/[id]/[key].vue?macro=true";
import { default as indexxwi4Na2t6nMeta } from "/vercel/path0/src/pages/orders/index.vue?macro=true";
import { default as indexXTkZc7aeSUMeta } from "/vercel/path0/src/pages/products/index.vue?macro=true";
import { default as merchandiseoX43IeJ7s6Meta } from "/vercel/path0/src/pages/products/merchandise.vue?macro=true";
import { default as variety_45packsC2m52RLUYDMeta } from "/vercel/path0/src/pages/products/variety-packs.vue?macro=true";
import { default as email_45sentQTWv2AUSMPMeta } from "/vercel/path0/src/pages/reactivate/email-sent.vue?macro=true";
import { default as list_45reactivateyCiPm7Lyh8Meta } from "/vercel/path0/src/pages/reactivate/list-reactivate.vue?macro=true";
import { default as perform_45reactivation3hgKu8lzIPMeta } from "/vercel/path0/src/pages/reactivate/perform-reactivation.vue?macro=true";
import { default as referralsNw2eV7n1H0Meta } from "/vercel/path0/src/pages/referrals.vue?macro=true";
import { default as signupzkJMp6rwDXMeta } from "/vercel/path0/src/pages/signup.vue?macro=true";
import { default as addressN1UABTAMDvMeta } from "/vercel/path0/src/pages/subscriptions/[id]/address.vue?macro=true";
import { default as indexgL54ZRO7mKMeta } from "/vercel/path0/src/pages/subscriptions/[id]/index.vue?macro=true";
import { default as edit5Vr6jm6MlTMeta } from "/vercel/path0/src/pages/subscriptions/coffee/[id]/edit.vue?macro=true";
import { default as newudrXHiUfMdMeta } from "/vercel/path0/src/pages/subscriptions/coffee/new.vue?macro=true";
import { default as index7iquHBCk5LMeta } from "/vercel/path0/src/pages/subscriptions/index.vue?macro=true";
import { default as editrm69aJNOENMeta } from "/vercel/path0/src/pages/subscriptions/oatmeal/[id]/edit.vue?macro=true";
import { default as new9O1Hiqm5WbMeta } from "/vercel/path0/src/pages/subscriptions/oatmeal/new.vue?macro=true";
import { default as list_45upgrade89d7ZDtUv4Meta } from "/vercel/path0/src/pages/upgrade/list-upgrade.vue?macro=true";
import { default as list_45upgradesKZudHTH1uFMeta } from "/vercel/path0/src/pages/upgrade/list-upgrades.vue?macro=true";
import { default as perform_45upgrade6WtDDDY3hXMeta } from "/vercel/path0/src/pages/upgrade/perform-upgrade.vue?macro=true";
export default [
  {
    name: "account-edit",
    path: "/account/edit",
    component: () => import("/vercel/path0/src/pages/account/edit.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/src/pages/account/index.vue")
  },
  {
    name: "account-payment-methods-id-edit",
    path: "/account/payment-methods/:id()/edit",
    component: () => import("/vercel/path0/src/pages/account/payment-methods/[id]/edit.vue")
  },
  {
    name: "account-payment-methods-new",
    path: "/account/payment-methods/new",
    component: () => import("/vercel/path0/src/pages/account/payment-methods/new.vue")
  },
  {
    name: "activate",
    path: "/activate",
    meta: activate5ELYE4hzfuMeta || {},
    component: () => import("/vercel/path0/src/pages/activate.vue")
  },
  {
    name: "admin-customer",
    path: "/admin/customer",
    meta: customer0TLxWbjsj1Meta || {},
    component: () => import("/vercel/path0/src/pages/admin/customer.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: index1kcBTKlDMxMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: login8p7DdjFDWsMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/login.vue")
  },
  {
    name: "admin-set-password",
    path: "/admin/set-password",
    meta: set_45passwordkrGVglQHZRMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/set-password.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/vercel/path0/src/pages/favorites.vue")
  },
  {
    name: "gifting",
    path: "/gifting",
    component: () => import("/vercel/path0/src/pages/gifting.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginLL9eJtzKSFMeta || {},
    component: () => import("/vercel/path0/src/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutzKCEFgv0brMeta || {},
    component: () => import("/vercel/path0/src/pages/logout.vue")
  },
  {
    name: "onetimes-id-edit",
    path: "/onetimes/:id()/edit",
    component: () => import("/vercel/path0/src/pages/onetimes/[id]/edit.vue")
  },
  {
    name: "onetimes-id",
    path: "/onetimes/:id()",
    component: () => import("/vercel/path0/src/pages/onetimes/[id]/index.vue")
  },
  {
    name: "orders-id-key",
    path: "/orders/:id()/:key()",
    component: () => import("/vercel/path0/src/pages/orders/[id]/[key].vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/src/pages/orders/index.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/vercel/path0/src/pages/products/index.vue")
  },
  {
    name: "products-merchandise",
    path: "/products/merchandise",
    component: () => import("/vercel/path0/src/pages/products/merchandise.vue")
  },
  {
    name: "products-variety-packs",
    path: "/products/variety-packs",
    component: () => import("/vercel/path0/src/pages/products/variety-packs.vue")
  },
  {
    name: "reactivate-email-sent",
    path: "/email-sent/:type",
    meta: email_45sentQTWv2AUSMPMeta || {},
    component: () => import("/vercel/path0/src/pages/reactivate/email-sent.vue")
  },
  {
    name: "reactivate-list-reactivate",
    path: "/reactivate/:email/:rcCustomerId/:rcCustomerHash/",
    meta: list_45reactivateyCiPm7Lyh8Meta || {},
    component: () => import("/vercel/path0/src/pages/reactivate/list-reactivate.vue")
  },
  {
    name: "reactivate-perform-reactivation",
    path: "/reactivate/perform-reactivation/:token/",
    meta: perform_45reactivation3hgKu8lzIPMeta || {},
    component: () => import("/vercel/path0/src/pages/reactivate/perform-reactivation.vue")
  },
  {
    name: "referrals",
    path: "/referrals",
    component: () => import("/vercel/path0/src/pages/referrals.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupzkJMp6rwDXMeta || {},
    component: () => import("/vercel/path0/src/pages/signup.vue")
  },
  {
    name: "subscriptions-id-address",
    path: "/subscriptions/:id()/address",
    component: () => import("/vercel/path0/src/pages/subscriptions/[id]/address.vue")
  },
  {
    name: "subscriptions-id",
    path: "/subscriptions/:id()",
    component: () => import("/vercel/path0/src/pages/subscriptions/[id]/index.vue")
  },
  {
    name: "subscriptions-coffee-id-edit",
    path: "/subscriptions/coffee/:id()/edit",
    component: () => import("/vercel/path0/src/pages/subscriptions/coffee/[id]/edit.vue")
  },
  {
    name: "subscriptions-coffee-new",
    path: "/subscriptions/coffee/new",
    component: () => import("/vercel/path0/src/pages/subscriptions/coffee/new.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/vercel/path0/src/pages/subscriptions/index.vue")
  },
  {
    name: "subscriptions-oatmeal-id-edit",
    path: "/subscriptions/oatmeal/:id()/edit",
    component: () => import("/vercel/path0/src/pages/subscriptions/oatmeal/[id]/edit.vue")
  },
  {
    name: "subscriptions-oatmeal-new",
    path: "/subscriptions/oatmeal/new",
    component: () => import("/vercel/path0/src/pages/subscriptions/oatmeal/new.vue")
  },
  {
    name: "upgrade-list-upgrade",
    path: "/upgrade/:email/:rcCustomerId/:rcCustomerHash/:subscriptionId",
    meta: list_45upgrade89d7ZDtUv4Meta || {},
    component: () => import("/vercel/path0/src/pages/upgrade/list-upgrade.vue")
  },
  {
    name: "upgrade-list-upgrades",
    path: "/upgrade/:email/:rcCustomerId/:rcCustomerHash/",
    meta: list_45upgradesKZudHTH1uFMeta || {},
    component: () => import("/vercel/path0/src/pages/upgrade/list-upgrades.vue")
  },
  {
    name: "upgrade-perform-upgrade",
    path: "/upgrade/perform-upgrade/:token/",
    meta: perform_45upgrade6WtDDDY3hXMeta || {},
    component: () => import("/vercel/path0/src/pages/upgrade/perform-upgrade.vue")
  }
]