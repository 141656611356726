import { ref } from 'vue'
import { defineStore } from 'pinia'

import { getCustomer, type Customer, updateCustomer } from '@rechargeapps/storefront-client'
import { useAuthStore } from './auth'

export const useCustomerStore = defineStore('recharge/customer', () => {
  const customer = ref<Customer>()

  const hasValidPaymentMethod = computed(() => customer.value?.has_valid_payment_method && Number(customer.value?.include?.payment_methods?.length) > 0)

  const loadCustomer = async () => {
    const { useSession } = useAuthStore()
    customer.value = await fetchWithRetry(() => getCustomer(useSession(), { include: [ 'payment_methods' ] }))
    return customer.value
  }

  const updateCustomerEmail = async (email: string) => {
    const { useSession } = useAuthStore()
    customer.value = await fetchWithRetry(() => updateCustomer(useSession(), { email }))
    return customer.value
  }

  const useCustomer = () => {
    if (!customer.value) {
      loadCustomer()
    }
    return customer
  }

  const reset = () => (customer.value = undefined)

  return {
    customer,
    hasValidPaymentMethod,
    loadCustomer,
    updateCustomerEmail,
    useCustomer,
    reset
  }
})
