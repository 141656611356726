import type { Attribute } from '@/types/shopify'

export const pick = <T extends object, K extends keyof T>(obj: T, ...keys: K[]) => (
  Object.fromEntries(
    keys
      .filter(key => key in obj)
      .map(key => [ key, obj[key] ])
  ) as Pick<T, K>
)

export const toAttributes = (obj: object): Attribute[] => Object.entries(obj).filter(([ key, value ]) => !!value).map(([ key, value ]) => ({ key, value }))
