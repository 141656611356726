import { ref } from 'vue'
import { defineStore } from 'pinia'

import { createPaymentMethod, listPaymentMethods, updateAddress } from '@rechargeapps/storefront-client'
import type { PaymentMethod, CreatePaymentMethodRequest } from '@rechargeapps/storefront-client'

export const usePaymentMethodStore = defineStore('recharge/payment-method', () => {
  const { useSession } = rechargeStores.useAuthStore()
  const { loadCustomer } = rechargeStores.useCustomerStore()
  const { useCustomerAuthHeaders } = shopifyStores.useCustomerStore()
  const { alert, handleError } = useAppStore()

  const loading = ref(false)
  const paymentMethods = ref<PaymentMethod[]>([])

  const loadPaymentMethods = async () => {
    loading.value = true
    const response = await listPaymentMethods(useSession(), { include: [ 'addresses' ] })
    paymentMethods.value = response.payment_methods
    loading.value = false
  }

  const usePaymentMethods = () => {
    if (paymentMethods.value.length === 0 && !loading.value) {
      loadPaymentMethods()
    }
    return paymentMethods
  }

  const create = async (payload: CreatePaymentMethodRequest) => {
    const pm = await createPaymentMethod(useSession(), payload)

    // Passing address_ids to createPaymentMethod doesn't seem to work..
    // https://storefront.rechargepayments.com/client/docs/types/interfaces/CreatePaymentMethodRequest/#address_ids
    if (payload.address_ids) {
      for (const id of payload.address_ids) {
        await updateAddress(useSession(), id, { payment_method_id: pm.id })
      }
    }

    paymentMethods.value.unshift(pm)
    // reload customer & include payment methods to reset hasValidPaymentMethod
    await loadCustomer()
    return pm
  }

  const update = async (payment_method: Partial<PaymentMethod>) => {
    const res = await fetch('/api/payment-method/update', {
      method: 'PUT',
      headers: useCustomerAuthHeaders(),
      body: JSON.stringify({ payment_method })
    })
    const data = await res.json()
    const index = paymentMethods.value.findIndex(i => i.id === data.id)
    if (index >= 0) {
      paymentMethods.value.splice(index, 1, data)
    }
    return data
  }

  const remove = async (id: number | string) => {
    try {
      const { error } = await useFetch('/api/payment-method/remove', {
        method: 'DELETE',
        headers: useCustomerAuthHeaders(),
        params: { id }
      })
      if (error.value) {
        throw new Error(error.value.data?.error)
      }
      paymentMethods.value = paymentMethods.value.filter(pm => pm.id !== id)
      navigateTo('/account')
      alert('We\'ve removed your payment method.')
    } catch (e) {
      handleError(e)
    }
  }

  const reset = () => {
    paymentMethods.value = []
    loading.value = false
  }

  return {
    loading,
    paymentMethods,
    loadPaymentMethods,
    usePaymentMethods,
    create,
    update,
    remove,
    reset
  }
})
