import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ vueApp: app, $config: { public: { shopifyUrl } } }) => {
  window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault()
    const error = new Error('unhandledrejection')
    error.cause = event.reason
    Sentry.captureException(error)
  })

  return {
    provide: {
      sentry: Sentry
    }
  }
})
