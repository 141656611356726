<template>
  <TransitionRoot
    :show="store.modal.visible"
    as="template"
  >
    <Dialog @close="close">
      <DialogOverlay @click.self="close" />
      <div class="fixed left-0 top-0 w-full h-full overflow-y-auto overflow-x-hidden outline-none p-4">
        <div class="relative flex-center min-h-[calc(100%-1rem)]">
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="motion-reduce:transition-none opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="motion-reduce:transition-none opacity-0 scale-95"
          >
            <DialogPanel
              data-testid="modal-dialog-panel"
              class="modal min-w-[92vw] max-w-[92vw] sm:min-w-[320px] sm:max-w-[500px] w-full relative px-4 py-6 bg-white rounded-2xl z-10"
            >
              <CloseButton
                class="absolute right-2 top-2 text-gray-800 hover:text-black rounded-full z-10"
                @click="close"
              />
              <Component
                :is="content.component"
                v-if="content"
                v-bind="content.props"
                v-on="content?.events"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue'
import { useAppStore } from '@/stores'

const store = useAppStore()
const { content, close } = store.modal
</script>
