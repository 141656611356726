import * as Sentry from '@sentry/nuxt'

export const sendToSentry = (e: Error | unknown) => {
  if (!(e instanceof Error)) {
    e = new Error(JSON.stringify(e, null, 2))
  }
  Sentry.captureException(e)
  console.warn('[exception]', e)
}

export const groupSentrySend = (msg: string, e: Error | unknown) => {
  if (!(e instanceof Error)) {
    e = new Error(JSON.stringify(e, null, 2))
  }
  const newError = new Error(msg)
  newError.cause = e
  Sentry.captureException(e)
  console.warn('[exception]', msg, e)
}
