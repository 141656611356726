<template>
  <AdminToolbar v-if="$auth.adminAuthenticated" />
  <template v-if="$auth.authenticated">
    <template v-if="$breakpoints.lg.value">
      <AppHeader />
      <NavigationMobile />
      <NavigationMobileProfile />
    </template>
    <div class="w-full mx-auto relative lg:flex max-w-screen-2xl">
      <NavigationDesktop v-if="!$breakpoints.lg.value" />
      <div
        class="grow min-w-0 bg-white z-0 shadow-sm"
        data-testid="router-view"
      >
        <slot />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import AddRequiredMethodModal from '@/components/payment-methods/AddRequiredMethodModal.vue'

const { $app } = useNuxtApp()
const pref = usePreferencesStore()
const store = rechargeStores.useCustomerStore()
onMounted(() => {
  if (pref.flags.showPaymentMethodModal !== false && store.customer && store.customer.subscriptions_active_count && !store.hasValidPaymentMethod) {
    setTimeout(() => {
      if ($app.modal.visible) {
        return
      }
      pref.flags.showPaymentMethodModal = false
      $app.modal.open(AddRequiredMethodModal)
    }, 5000)
  }
})
</script>
