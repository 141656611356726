import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(({ vueApp: app }) => {
  const { public: { gtm: { id, enabled } } } = useRuntimeConfig()

  app.use(createGtm({
    id: id,
    enabled: Boolean(enabled)
  }))
})
