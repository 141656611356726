import { defineStore } from 'pinia'

export const usePreferencesStore = defineStore('preferences', () => {
  const dismissMostRecentOrderId = ref<string>()
  const showFeaturesAlert = ref([ 'coffee' ])
  const showSwipeToast = ref(true)
  const showFavoritesToggleToast = ref(true)
  const showReceivedGiftModal = ref(true)

  const flags = ref<Record<string, boolean>>({})

  const reset = () => {
    flags.value = {}
    showFeaturesAlert.value = [ 'coffee' ]
    dismissMostRecentOrderId.value = undefined
    showSwipeToast.value = showFavoritesToggleToast.value = showReceivedGiftModal.value = true
  }
  return {
    flags,
    showFeaturesAlert,
    dismissMostRecentOrderId,
    showSwipeToast,
    showFavoritesToggleToast,
    showReceivedGiftModal,
    reset
  }
}, {
  persist: {
    pick: [
      'flags',
      'dismissMostRecentOrderId',
      'showFeaturesAlert',
      'showSwipeToast',
      'showFavoritesToggleToast',
      'showReceivedGiftModal'
    ]
  }
})
