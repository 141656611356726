<template>
  <WebsiteHeader v-if="wasReferred" />
  <div
    data-screen="authentication"
    class="p-4 flex items-center justify-center h-screen"
  >
    <div class="flex-center flex-col grow gap-8 z-10">
      <div
        v-if="splash"
        class="text-center"
      >
        <h1 class="text-3xl mb-4">
          Welcome!
        </h1>
        <h5 class="text-lg">
          Delicious breakfast awaits.
        </h5>
      </div>
      <div class="flex flex-col w-full gap-3 xs:gap-6 p-8 max-w-sm mx-auto bg-white rounded-2xl shadow-sm">
        <img
          v-if="!wasReferred"
          src="@/assets/oats-overnight.svg"
          alt="Oats Overnight"
          class="w-12 xxs:w-18 xs:w-20 mx-auto"
          width="80"
          height="88"
        >
        <slot />
      </div>
    </div>
    <SvgIcon
      v-if="splash === 'gifting'"
      name="gift-credits"
      class="fixed rotate-12 bottom-0 left-0 right-0 -translate-x-1/4 translate-y-1/4 aspect-square z-0 opacity-20 max-w-screen-sm"
    />
  </div>
</template>

<script setup lang="ts">
const { public: { shopifyUrl } } = useRuntimeConfig()
const wasReferred = computed(() => {
  const { referrer } = document
  return referrer.includes(shopifyUrl)
    || referrer.includes('www.oatsovernight.com')
})
const { query: { splash } } = useRoute()
</script>
