const log = logger('auth')

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error) {
    return {}
  }
  // Initialize auth
  const auth = useAuthStore()
  const rechargeCustomer = rechargeStores.useCustomerStore()
  await auth.init()
  // Watch for auth changes, redirect if not authenticated
  const currentRoute = useRoute()
  watch(() => auth.authenticated, () => {
    const { meta: { publicPath } } = currentRoute
    const { authenticated, adminAuthenticated } = auth
    log('[change] authenticated?', authenticated, 'public?', publicPath, 'admin?', adminAuthenticated)
    if (!authenticated && !publicPath) {
      navigateTo(adminAuthenticated ? '/admin/customer' : '/login')
    }
  })
  return {
    provide: {
      auth,
      rechargeCustomer
    }
  }
})
